import { makeObservable, observable, action } from 'mobx';
import apiService from '../services/apiServices';
import { cacheService } from '../services/cacheService';

class ParticipantsStore {
  user = null;
  kickOffModal = false;
  blockUserModal = false;
  blockedUsersList = [];
  userIsBlocked = false;

  constructor() {

    makeObservable(this, {
      user: observable,
      kickOffModal: observable,
      blockUserModal: observable,
      blockedUsersList: observable,
      userIsBlocked: observable,
      handleKickOff: action.bound,
      handleBlockUser: action.bound,
      handleUnBlockUser: action.bound,
      kickOffModalToggle: action.bound,
      blockUserModalToggle: action.bound,
      setBlockedUsers: action.bound,
      setBlockedUser: action.bound,
    });

  }

  kickOffModalToggle(user) {
    if(user){
      this.user = user
      this.kickOffModal = true;
    }else{
      this.user = null
      this.kickOffModal = false;
    }
  }

  blockUserModalToggle(user) {
    if(user){
      this.user = user
      this.blockUserModal = true;
    }else{
      this.user = null
      this.blockUserModal = false;
    }
  }

  setBlockedUsers(arr){
    this.blockedUsersList = arr
  }

  setBlockedUser(bool){
    this.userIsBlocked = bool
  }

  async handleKickOff(duration){
    const roomId = Number(cacheService.get('RoomId'))
    try {
        await apiService.kickOffUser({user_public_id:this.user.publicId, room_public_id:roomId, duration});
        this.kickOffModal = false;
    } catch (e) {
        console.warn('ERROR: ', e.message);
    }
  }

  async handleBlockUser(){
    const roomId = Number(cacheService.get('RoomId'))
    try {
        await apiService.userBlok( {user_public_id: this.user?.publicId, room_public_id:roomId} );
        this.blockedUsersList = [...this.blockedUsersList, {public_id: this.user?.publicId, username: this.user.name}]
        this.blockUserModal = false;
    } catch (e) {
        console.warn('ERROR: ', e.message);
    }
  }

  async handleUnBlockUser(uid){
    const roomId = Number(cacheService.get('RoomId'))
    try {
        await apiService.userUnBlok( {user_public_id: uid, room_public_id: roomId} );
        this.blockedUsersList = this.blockedUsersList.filter(user => user.public_id !== uid)
    } catch (e) {
        console.warn('ERROR: ', e.message);
    }
  }
}

export const participantsStore = new ParticipantsStore();




