import { participantsStore } from "./participantsStore";
import {cacheService} from "../services/cacheService";
const {setBlockedUser} = participantsStore;

export const PRIVATE_CALL_STATUS = {
  0: 'None',
  1: 'Invited',
  2: 'Connected',
  3: 'Accepted',
  4: 'Rejected',
}

export const MEDIA_DEVICE_STATUS = {
    enabled: 'enabled',
    disabled: 'disabled',
    notFound: 'notFound',
}

export const WARNING_TYPES = {
  0: "error",
  1: "warning",
  2: "info",
  3: "success",
}

export const MICROPHONE_STATUS = {
    0: 'MUTE',
    1: 'UNMUTE',
    2: 'FREEZE',
    3: 'MUTE_ALL',
}

export const SELECT_OPTION = [
  {
      value: 2,
      visibleMinutes: 2,
      translationName: 'minutes'
  },
  {
      value: 10,
      visibleMinutes: 10,
      translationName: 'minutes'
  },
  {
      value: 30,
      visibleMinutes: 30,
      translationName: 'minutes'
  },
  {
      value: 60,
      visibleMinutes: 1,
      translationName: 'hours'
  },
  {
      value: 1440,
      visibleMinutes: 24,
      translationName: 'hours'
  }
]

export const ERROR_MAP = {
    'expired_session': {
      redirectPath: "/",
    },
    "permission_denied": {
        redirectPath: cacheService.getAuthToken() ? "/dashboard" : "/",
    },
    'user_deactivated': {
      redirectPath: "/",
    },
    'invalid_credentials': {
      redirectPath: "/",
    },
    'email_not_confirm': {
      redirectPath: "/expired-link",
    },
    'user_does_not_have_permission': {
      modal_title: "“Oops!”",
      isModal: true,
      closeable: true,
      callBack: () => {
        setBlockedUser(true)
      }
    },
    'admin_has_blocked': {
      modal_title: "“Oops!”",
      isModal: true,
      closeable: true,
      callBack: () => {
        setBlockedUser(true)
      }
    },
    'room_does_not_exist': {
      redirectPath: "/404",
    },
    'connection_lost': {
      modal_title: "Connection was lost!",
      isModal: true,
      connected: false
    },
    'user_freezed': {
      modal_title: "Muted",
      isModal: true,
      closeable: true,
      actionBtnOk: true
    },
    'private_call': {
      modal_title: "Private Call",
      isModal: true,
      closeable: true,
      actionBtnOk: true
    },
    "500": {
        redirectPath: "/maintenance",
    }
}

export const WARNING_OPTIONS = {
  audio_device: {
    type: WARNING_TYPES[0],
  },
  video_device: {
    type: WARNING_TYPES[0],
  }
}