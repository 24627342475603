import {useEffect} from "react";
import {translationStore} from "../store/translationStore";

const useTranslates = () => {
    const {getTranslationData, activeLanguage} = translationStore;

    useEffect(async () => {
        await getTranslationData();
    }, [activeLanguage]);
}

export default useTranslates;