import {makeObservable, observable, action} from "mobx";

class AuthStore {
    slide = 0; // Authorization Slide percentage
    email = ""; // user email
    password = ""; // user pass
    new_password1 = ""; // user create pass on signUp | forgot pass
    new_password2 = ""; // user create pass on NftConfirm | forgot pass
    nickname = ""; // user create nickname on signUp
    isUser = true; // is actual user
    notification = false; // user check in signUp
    forgot = false; // user forgot pass flag
    public_id = -1;
    doubleLogin = false;
    success = undefined;
    inviteMessage = "";
    deletePopup = false;
    deleteReasons = [];
    termsPopup = "";
    token = "";

    constructor() {
        makeObservable(this, {
            slide: observable,
            email: observable,
            password: observable,
            isUser: observable,
            nickname: observable,
            forgot: observable,
            notification: observable,
            public_id: observable,
            doubleLogin: observable,
            success: observable,
            inviteMessage: observable,
            deletePopup: observable,
            deleteReasons: observable,
            termsPopup: observable,
            token: observable,
            setPlusSlide: action.bound,
            setSlide: action.bound,
            setMinusSlide: action.bound,
            setIsUser: action.bound,
            setEmail: action.bound,
            setPassword: action.bound,
            setNewPassword1: action.bound,
            setNewPassword2: action.bound,
            setNotification: action.bound,
            setNickname: action.bound,
            setForgot: action.bound,
            setDoubleLogin: action.bound,
            setSuccess: action.bound,
            setInviteMessage: action.bound,
            setDeletePopup: action.bound,
            setDeleteReasons: action.bound,
            setTermsPopup: action.bound,
            setToken: action.bound,
        });
    }

    setEmail(string) {
        this.email = string;
    }

    setPassword(string) {
        this.password = string;
    }

    setNewPassword1(string) {
        this.new_password1 = string;
    }

    setNewPassword2(string) {
        this.new_password2 = string;
    }

    setNickname(string) {
        this.nickname = string;
    }

    setNotification(flag) {
        this.notification = flag;
    }

    setIsUser(flag) {
        this.isUser = flag;
    }

    setForgot(flag) {
        this.forgot = flag;
    }

    setPlusSlide(val) {  // set percentage by content Increment
        // const percentage =
        //     this.isUser && !this.forgot ? 50 : this.forgot ? 25 : 16.6666;
        // this.slide += percentage;
        if(val) {
            this.slide = val;
        } else {
            this.slide += 1;
        }
    }

    setMinusSlide() { // set percentage by content Decrement
        // const percentage =
        //     this.isUser && !this.forgot ? 50 : this.forgot ? 25 : 16.6666;

        // this.slide -= Math.abs(percentage);
        this.slide -= 1;
    }

    setSlide(number) {
        this.slide = number;
    }

    setPublicId(number) {
        this.public_id = number;
    }

    setClear() {
        this.setSlide(0);
        this.setEmail("");
        this.setPassword("");
    }

    setDoubleLogin(bool) {
        this.doubleLogin = bool;
    }

    setSuccess(bool) {
        this.success = bool;
    }

    setInviteMessage(string) {
        this.inviteMessage = string;

        setTimeout(() => {
            this.inviteMessage = ""
        }, 5000);
    }

    setDeletePopup(bool) {
        this.deletePopup = bool;
    }

    setDeleteReasons(arr) {
        this.deleteReasons = arr;
    }

    setTermsPopup(str) {
        this.termsPopup = str;
    }

    setToken(token){
        this.token = token
    }
}

export const authStore = new AuthStore();
