import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import gsap from "gsap";
import {isDesktop, isTablet} from "react-device-detect";
// import isFirefox from "../../helpers/isFirefox";
import "./index.scss";
// import { loadingStore } from "../../store/loadingStore";
import { observer } from "mobx-react-lite";

const TransitionRouter = observer(({children}) => {
    const location = useLocation();
    const containerRef = useRef(null);
    // const {unityLoading} = loadingStore;

    useEffect(() => {
        const timeline = gsap.timeline();
        timeline.from(containerRef.current, {
            opacity: 0.3,
            x: "10vw",
            // ease: "power2.out",
        }).to(containerRef.current, {
            opacity: 1,
            x: "0",
            ease: "power2.out",
        });

        return () => {
            timeline.kill();
            containerRef.current.removeAttribute('transform')
        };
    }, [location]);
    
    return (
        <div
            className={`layer-content ${!isDesktop ? "mobile" : ""} ${isTablet ? "tablet" : ""}`} ref={containerRef}>
            {children}
        </div>
    );
})

export default TransitionRouter;
