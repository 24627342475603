import React from 'react';
import {translationStore} from "../../store/translationStore";
import {observer} from "mobx-react-lite";
import {loadingStore} from "../../store/loadingStore";
import backgroundVideo from "../../assets/video/bme/background.gif";
import backgroundVideoMobile from "../../assets/video/bme/background_mobile.gif";
import {isMobile, isTablet} from "react-device-detect";

const Loading = observer(() => {
    const {translationData} = translationStore;
    const {translateLoading, unityLoading, avatarLoading} = loadingStore;

    return (
        <div className={`loading-block ${!(translateLoading || unityLoading || avatarLoading) ? "backLayer" : ""}`}>
            <img alt="background" id="background" src={(isMobile || isTablet) ? backgroundVideoMobile : backgroundVideo} />
            <div className="char-block"></div>
            <span className="loading-label">{translationData?.["please_wait"] || "Please wait"}...</span>
        </div>
    );
})

export default Loading;
