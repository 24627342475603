import React, {useEffect, useMemo, useState} from "react";
import {isMobile} from "react-device-detect";
import {Link, useLocation, useNavigate} from "react-router-dom";
import logo from "../../assets/images/bme/logo.svg";
import {cacheService} from "../../services/cacheService";
import {copyTextToClipboard} from "../../utils";
import {observer} from "mobx-react-lite";
import useErrorHandler from "../../hooks/useErrorHandler";
import apiService from "../../services/apiServices";
import {unityStore} from "../../store/unityStore";
import {translationStore} from "../../store/translationStore";
import {roomStore} from "../../store/roomStore";
import {participantsStore} from "../../store/participantsStore";
import {loadingStore} from "../../store/loadingStore";
import {unityLayoutStore} from "../../store/unityLayoutStore";
import {privateTalkStore} from "../../store/privateTalkStore";
import "./style.scss";

const Mobile = observer(() => {
    const [copied, setCopied] = useState(false);
    const [username, setUsername] = useState(cacheService.get("UserName"));
    const [roomId, setRoomId] = useState(cacheService.get("RoomId"));
    const [authToken, setAuthToken] = useState(cacheService.getAuthToken());
    const {setIsActiveMobile, setHooryToken, setRoomToken, clearUnityData} = unityStore;
    const {roomName, roomBackground, setRoomName, setRoomBackgroundImg, clearRoomData} = roomStore;
    const {translationData, activeLanguage} = translationStore;
    const {userIsBlocked, setBlockedUser} = participantsStore;
    const {toggleUnityLoading} = loadingStore;
    const {clearUnityLayoutData} = unityLayoutStore;
    const {clearPrivateTalkData} = privateTalkStore;
    const currentLocation = useLocation();
    const navigate = useNavigate();
    const [url, setUrl] = useState("");

    useErrorHandler();

    const getRoomByID = (id) => apiService.getRoomByID(id);
    const setUserGuest = (id) => apiService.setUserGuest(id);

    useEffect(async () => {
        const curRoomId = currentLocation.pathname.split("/dashboard/")[1];
        const curAuthToken = cacheService.getAuthToken();
        if(username !== cacheService.get("UserName")) setUsername(cacheService.get("UserName"));
        if(roomId !== cacheService.get("RoomId")) setRoomId(curRoomId);
        if(authToken !== curAuthToken) setAuthToken(curAuthToken);
        await getRoomData(curRoomId);

        if (!curAuthToken && curRoomId) {
            const {data: {data: {token}}} = await setUserGuest(curRoomId);
            setAuthToken(token)
            cacheService.set('Authorization', token);
        }
    }, []);

    useEffect(async () => {
        if (userIsBlocked && isMobile) {
            setHooryToken("");
            setRoomToken("");
            toggleUnityLoading(false);
            setBlockedUser(false);
            clearUnityLayoutData();
            clearUnityData();
            clearRoomData();
            clearPrivateTalkData();
            if(cacheService.get("UserType") === "user") {
                navigate("/dashboard", {replace: true});
            } else {
                cacheService.clear();
                navigate("/", {replace: true});
            }
        }
    }, [userIsBlocked]);

    const getRoomData = async (id) => {
        if (!!Number(id)) {
            if (Number(id) !== Number(roomId) || !(roomName && roomBackground)) {
                try {
                    const {data: {data}} = await getRoomByID(id);
                    setRoomName(data.room_name);
                    setRoomBackgroundImg(data.preview_image);
                    cacheService.set("RoomId", id);
                    setRoomId(id);
                    setUsername(data.creator.username);
                } catch (e) {
                    console.error("ERROR: ", e)
                }
            }
        }
    }

    const handleCopyLink = () => {
        setCopied(true);

        const url = `${window.location.origin}/dashboard/${roomId}`;
        void copyTextToClipboard(url);

        setTimeout(() => {
            setCopied(false);
        }, 800);
    };

    useMemo(async () => {
        const defaultUrl = "https://pandamr.page.link/?link=";
        const secondaryUrl = "&apn=io.pandamr.metamobile&isi=6443733758&ibi=io.pandamr.metamobile&efr=1"
        const encodedUrl = encodeURIComponent(`https://pandamr.io/dashboard?command=LoginAndOpenRoom&roomId=${roomId}&userToken=${authToken}`);

        setUrl(String(defaultUrl + encodedUrl + secondaryUrl));
    }, [authToken, roomId])

    return (
        <div className="event-in-mobile-container">
            <img alt={"Img"} src={roomBackground} className="background"/>
            <div className="event-title-container">
                <div className="event-title-left-container">
                    <Link className="back" to={`/dashboard`} onClick={() => setIsActiveMobile(false)}>
                        <i className="icon-back"/>
                    </Link>
                    <div className="title-container">
                        <p className="title">{roomName}</p>
                        <span>
                            {activeLanguage !== 'ARM' ? (translationData?.["by"] || "by") : ''} Soft. {activeLanguage === 'ARM' ? (translationData?.["by"] || "ի կողմից") : ''}
                        </span>
                    </div>
                </div>
                <button
                    className="p-mr-event-popover-action tooltip"
                    onClick={handleCopyLink}
                >
                    <span className={`tooltiptext ${copied ? "active" : ""}`}>{translationData?.["copied"] || "Copied!"}</span>
                    <i className="icon-link"/>
                </button>
            </div>
            <div className="open-on-mobile">
                <img alt={"Logo"} src={logo} className="logo"/>
                <p>{translationData?.["open_application_to"] || "Open application to join this space"}</p>
                <>
                    <a className="open-in-app"
                       href={url}>
                        {translationData?.["open_in_app"] || "Open in app"}
                    </a>
                </>
            </div>
        </div>
    );
});

export default Mobile;
