import React, {lazy, Suspense} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import routes from "./routes";
import {unityStore} from "./store/unityStore";
import {authStore} from "./store/authStore";
import {dashboardStore} from "./store/dashboardStore";
import DoubleLogin from "./components/Popups/DoubleLogin";
import Loading from "./components/Loading";
import InviteShowPopup from "./components/Popups/InviteShowPopup";
import ErrorModal from "./components/ModalError";
import RotateDevice from "./components/RotateDevice";
import Mobile from "./containers/mobile";
import ParticipantsPopup from "./components/Popups/ParticipantsPopup";
import InviteToHomeSpace from "./components/Popups/InviteToHomeSpace";
import TransitionRouter from "./components/TransitionRouter";
import useRotate from "./hooks/useRotate";
import useTranslates from "./hooks/useTranslates";
import backgroundVideo from "./assets/video/bme/background.mp4";
import backgroundVideoMobile from "./assets/video/bme/background_mobile.mp4";
import {isMobile, isTablet} from "react-device-detect";

const UnityLayout = lazy(() => import("./containers/unityLayout"));

const App = observer(() => {
    const {loadUnity, isActiveMobile} = unityStore;
    const {doubleLogin, inviteMessage} = authStore;
    const {inviteData, clearInviteData} = dashboardStore;
    const showRotateDevice = useRotate(false);

    // useEffect(() => {
    //   navigator.getInstalledRelatedApps().then((data) => {
    //     console.log(data)
    //     console.log(navigator)
    //   })
    // }, [])

    useTranslates();

    return (
        <Suspense fallback={null}>
            <BrowserRouter>
                <video autoPlay muted loop playsInline id="background">
                    <source src={(isMobile || isTablet) ? backgroundVideoMobile : backgroundVideo} type="video/mp4" />
                </video>
                <TransitionRouter>
                    <Routes>
                        {routes.map((item, index) => (
                            <Route
                                key={index}
                                path={item.path}
                                element={<item.component />}
                                title={item.title}
                                exact
                            />
                        ))}
                        <Route path="*" element={<Navigate to="/404" replace />} />
                    </Routes>
                </TransitionRouter>
                <Suspense fallback={null}>
                    {loadUnity && !isActiveMobile && <UnityLayout />}
                    {isActiveMobile && <Mobile />}
                </Suspense>
                {doubleLogin && <DoubleLogin />}
                {!!inviteMessage && <InviteShowPopup />}
                {!!inviteData?.sender_user && (
                    <InviteToHomeSpace
                        user={inviteData?.sender_user}
                        roomLink={inviteData?.link}
                        clearData={clearInviteData}
                    />
                )}
                <ParticipantsPopup />
            </BrowserRouter>
            {showRotateDevice && <RotateDevice />}
            <Loading />
            <ErrorModal />
        </Suspense>
    );
});

export default App
