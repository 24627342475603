
import { observer } from "mobx-react-lite";
import { errorStore } from "../../store/errorStore";
import { ReactComponent as CloseIco } from "../../assets/icons/close.svg";
import { ReactComponent as ConnectionLost } from "../../assets/icons/connection-lost.svg";
import { ReactComponent as InternetConnected } from "../../assets/icons/internet-connected.svg";
import "./index.scss";
import { translationStore } from "../../store/translationStore";
import { useRef } from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import WarningPopup from "./WarningPopup";


const ErrorModal = observer(() => {
    const modalRef = useRef();
    const {closeModal, hasOnModal, modalMessage, closeable, modalTitle, connected, actionBtnOk} = errorStore;
    useOnClickOutside(modalRef, closeModal);
    const {translationData} = translationStore

    return (
            <>
                {
                    hasOnModal &&
                    <div className={`error_popup_holder ${ !closeable ? 'fullscreen' : ""}`}>
                        <div ref={modalRef} className={`error_modal connection_modal`}>
                            <div className="modal_body">
                                <div className="modal_title">
                                    <div></div>
                                    <div className="title_text">
                                        {modalTitle}
                                    </div>
                                    <div>
                                        {
                                            closeable && (
                                                <CloseIco className="error_ico" onClick={closeModal}/>
                                            )
                                        }
                                    </div>
                                </div>
                                {
                                    connected === undefined ? null :
                                    connected ? <InternetConnected/> : <ConnectionLost className="connection_lost"/>
                                }
                                <div className="modal_text">{translationData?.[modalMessage]}</div>
                                <div>
                                    {
                                        actionBtnOk && (
                                            <button className="ok_btn" onClick={closeModal}>OK</button>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <WarningPopup/>
            </>
    )
})

export default ErrorModal