import { observer } from "mobx-react-lite"
import { errorStore } from "../../../store/errorStore"
import styles from './style.module.scss'
import { translationStore } from "../../../store/translationStore";

 const WarningPopup = observer(()=> {
    const { warningMap } = errorStore;
    const { translationData } = translationStore;

    return (
        <>
        {
            warningMap.map(item => {
                return (
                    <div key={item.key} className={`${styles.warning_popup} ${styles[item.type]}`}>
                        <div className={styles.popup_icon}></div>
                        <div className={styles.modal_text}>{translationData?.[item.key]}</div>
                    </div>
                )
            })
        }
        </>
    )
})

export default WarningPopup