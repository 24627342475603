import {makeObservable, observable, action} from "mobx";

class LoadingStore {
    translateLoading = false;
    unityLoading = false;
    dataLoading = false;
    avatarLoading = false;
    cardLoading = false;

    constructor() {
        makeObservable(this, {
            translateLoading: observable,
            unityLoading: observable,
            dataLoading: observable,
            avatarLoading: observable,
            cardLoading: observable,
            toggleTranslateLoading: action.bound,
            toggleUnityLoading: action.bound,
            toggleDataLoading: action.bound,
            toggleAvatarLoading: action.bound,
            toggleCardLoading: action.bound,
        });
    }

    toggleTranslateLoading(bool) {
        this.translateLoading = bool;
    }

    toggleUnityLoading(bool) {
        this.unityLoading = bool;
    }

    toggleDataLoading(bool) {
        this.dataLoading = bool;
    }

    toggleAvatarLoading(bool) {
        this.avatarLoading = bool;
    }

    toggleCardLoading(bool) {
        this.cardLoading = bool;
    }
}

export const loadingStore = new LoadingStore();

