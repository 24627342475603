import React from "react";
import "./index.scss";
import backgroundVideo from "../../assets/video/bme/background.gif";
import backgroundVideoMobile from "../../assets/video/bme/background_mobile.gif";
import {isMobile, isTablet} from "react-device-detect";

const RotateDevice = () => {
    return (
        <div className="rotate-container">
            <img alt="background" id="background" src={(isMobile || isTablet) ? backgroundVideoMobile : backgroundVideo} />
            <span className="rotate-img"/>
        </div>
    )
}

export default RotateDevice;