import {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";

const useRotate = () => {
    const [showRotateDevice, setShowRotateDevice] = useState(false);
    useEffect(() => {
        if (isMobile) {
            const resizeObserver = new ResizeObserver((entries) => {
                for (const entry of entries) {
                    if(entry.borderBoxSize) {
                        setShowRotateDevice(entry.borderBoxSize[0].inlineSize > entry.borderBoxSize[0].blockSize);
                    } else {
                        setShowRotateDevice(entry.contentRect.width > entry.contentRect.height);
                    }
                }
            });
            resizeObserver.observe(document.querySelector("#root"))
        }
    }, []);

    return showRotateDevice;
}

export default useRotate;