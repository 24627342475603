import { useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useOnClickOutside from '../../../../../hooks/useOnClickOutside';
import { translationStore } from '../../../../../store/translationStore';
import { ReactComponent as ArrowParticipants } from "../../../../../assets/icons/arrow_participants.svg";
import { SELECT_OPTION } from '../../../../../store/constatns';

const Select = observer(({timeRange, timeRangeHandler, error})=> {
    const ref = useRef();
    const [selectActive, setSelectActive] = useState(false);
    const {translationData} = translationStore;
    useOnClickOutside(ref, ()=>setSelectActive(false));
    const selectHandler = (timeRange) => {
        timeRangeHandler(timeRange);
        setSelectActive(false);
    }
    const values = useMemo(()=> SELECT_OPTION, [SELECT_OPTION])

    return (
        <div ref={ref} className={`select_section ${selectActive ? 'active' : ''}`}>
            <div className={`interval_select ${error}`} onClick={()=>setSelectActive(prev=>!prev)}>
                {
                    timeRange ? <div>
                        {timeRange.visibleMinutes}  {translationData?.[timeRange.translationName]}
                    </div> :
                    <span className='select_placeholder'>Select</span>
                }
                <ArrowParticipants/>
            </div>
            <ul className='select_time'>
                {
                    values.map((item, index) => {
                        if(item.value === timeRange?.value) return null
                        return <li key={index} className='option' onClick={()=> selectHandler(item)}>{item.visibleMinutes} {translationData?.[item.translationName]}</li>
                    })
                }
            </ul>
        </div>
    )
})

export default Select