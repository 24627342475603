import { makeObservable, observable, action } from 'mobx';

class RoomStore {
  likesNum = 0;
  isLiked = false;
  roomName = '';
  roomBackground = '';

  constructor() {

    makeObservable(this, {
      likesNum: observable,
      isLiked: observable,
      roomName: observable,
      roomBackground: observable,
      setNumberLikes: action.bound,
      setIsLiked: action.bound,
      setRoomName: action.bound,
      setRoomBackgroundImg: action.bound,
      clearRoomData: action.bound,
    });

  }

  setNumberLikes(num) {
    this.likesNum = num;
  }

  setIsLiked(bool) {
    this.isLiked = bool;
  }

  setRoomName(name) {
    this.roomName = name;
  }

  setRoomBackgroundImg(url) {
    this.roomBackground = url;
  }

  clearRoomData() {
    this.likesNum = 0;
    this.isLiked = false;
    this.roomName = '';
    this.roomBackground = '';
  }
}

export const roomStore = new RoomStore();
