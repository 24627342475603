import {makeObservable, observable, action, runInAction} from "mobx";
import {cacheService} from "../services/cacheService";
import apiServices from "../services/apiServices";
import {Languages} from "../constants";
import {loadingStore} from "./loadingStore";

class TranslationStore {
    activeLanguage = Languages[cacheService.get("Language")]?.title || Languages["ENG"].title;
    translationData = {};
    allTranslates = {};

    constructor() {
        makeObservable(this, {
            activeLanguage: observable,
            translationData: observable,
            allTranslates: observable,
            setActiveLanguage: action.bound,
            getTranslationData: action.bound,
            setAllTranslates: action.bound,
        });
    }

    setActiveLanguage(data) {
        this.activeLanguage = data.title;
        cacheService.set("Language", data.title);
    }

    setTranslationData(data) {
        this.translationData = data;
    }

    setAllTranslates(data, lang) {
        this.allTranslates = {
            ...this.allTranslates,
            [lang]: data
        }
    }

    getTranslationData = async () => {
        try {
            loadingStore.toggleTranslateLoading(true);
            if (!this.allTranslates.hasOwnProperty(Languages[this.activeLanguage]?.title)) {
                cacheService.set("Language", Languages['ENG'].title);
                this.activeLanguage = 'ENG';
                const {data: {data}} = await apiServices.getTranslation("eng");
                document.documentElement.lang = Languages[this.activeLanguage]?.value || "eng";
                runInAction(() => {
                    this.setAllTranslates(data, Languages[this.activeLanguage]?.title)
                    this.setTranslationData(data)
                })
            } else {
                this.setTranslationData(this.allTranslates[Languages[this.activeLanguage]?.title])
            }
        } catch (e) {
            console.warn("ERROR: ", e.message);
        } finally {
            loadingStore.toggleTranslateLoading(false);
        }
    }


}

export const translationStore = new TranslationStore();